import React, { useState } from 'react';
import axios from '../config/AxoisConfig';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { saveToken } from '../store/MemberSlice';

const Login = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/member/login', { id : id, pwd : password });
          alert('로그인 성공');
        console.log(response.headers);
        const token = response.headers['authorization'].split(' ')[1];
        console.log('tokken 정보 : ',token);
        dispatch(saveToken({...response.data, token: token}));
        navigate('/');
      
    } catch (error) {
      alert('로그인 실패');
      console.error(error);
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">로그인 페이지</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="text"
          placeholder="아이디"
          value={id}
          onChange={(e) => setId(e.target.value)}
          required
          className="login-input"
        />
        <br />
        <input
          type="password"
          placeholder="암호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="login-input"
        />
        <br />
        <button type="submit" className="login-button">로그인</button>
      </form>
    </div>
  );
};

export default Login;