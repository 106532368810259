import axios from "../config/AxoisConfig";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import '../css/board_view.css'
import { useSelector } from "react-redux";
export default function BoardViewPage() {
  const {bno} = useParams();
  const commentTextarea = useRef();
  const info = useSelector(state => state.member.value);
  let [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/board/detail/'+bno);
          console.log('boardView',response);
          setData(response.data);    
          //console.log(data);   
    } catch (error) {
      alert('로그인 실패');
      console.error(error);
    }
  };
  fetchData();
  },[bno]);
  const addComment = () => {
    axios.post('/comment/add', {
      commentContent: commentTextarea.current.value,
      token : info.token,
      boardNo : data.board.boardNo
    }).then((response) => {
      console.log(response);
    });
  }
  if (!data) {
    return <div>Loading...</div>;
  }

    return (
    <div>
       <div className="container">
      <div className="content">
        <div className="row">
          <span className="title">글번호</span>
          <span>{data.board.boardNo}</span>
        </div>
        <div className="row">
          <span className="title">제목</span>
          <span>{data.board.boardTitle}</span>
        </div>
        <div className="row">
          <span className="title">작성자</span>
          <span>{data.board.boardMemberNick}</span>
        </div>
        <div className="row">
          <span className="title">조회수</span>
          <span>{data.board.boardCount}</span>
        </div>
        <div className="content-text row" dangerouslySetInnerHTML={{__html : data.board.boardContent}}>
        </div>
        <div className="row">
          <span>좋아요 : {data.board.boardLike}</span>
          <span>싫어요 : {data.board.boardHate}</span>
        </div>
        <div>
          <span>첨부파일</span>
          <div> 
            {
              data.fileList.map((file) => {
                return (
                  <div key={file.fileNo}>
                    <a href={'/file/download?fileNo='+file.fileNo}>{file.fileName}</a>
                  </div>
                );
              })
            }
          </div>
        </div>
      <hr></hr>
      { info.token == null ? <div className="comment-section">
        <input type="text" ref={commentTextarea} placeholder="댓글을 입력하세요" className="input" />
        <button className="btn_add_comment" onClick={addComment}>댓글작성</button>
      </div> : <div></div>
      }
      <div className="content-footer">
        <button onClick={()=>{
          window.history.back();
        }}>뒤로가기</button>
        {
          info.token == null ? <div></div> : <button>수정</button>
        }
        {
          info.token == null ? <div></div> : <button>삭제</button>
        }
      </div>
    </div>
    <hr></hr>
    {
      data.commentList.map((comment) => {
        return (
          <div key={comment.commentNo} className="comment">
            <div className="comment-row">
              <span className="comment-writer">작성자 : {comment.writer}</span>
              <span className="comment-date">작성일 : {comment.cdate}</span>
              <span className="comment-clike">좋아요 : {comment.clike}</span>
              <span className="comment-chate">싫어요 : {comment.chate}</span>
            </div>
            <div className="comment-row">
              <span className="comment-content">{comment.comment}</span>
            </div>
          </div>
        );
      })
    }
    </div>
  </div>);
}