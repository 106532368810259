import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../store/MemberSlice";
import '../css/header.css';
export default function Header() {
  const info = useSelector(state => state.member.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(info);
  const logout = () => {
    dispatch(clearToken());
    alert('로그아웃 처리되었습니다.');
    navigate("/")
  }
  return (
    <>
      <header>
        <nav>
          <ul>
            <li>
              <Link to="/">홈</Link>
            </li>  
            { Object.keys(info).length === 0 ?
              <>
             <li>
              <Link to="/register">회원등록</Link>
            </li>  
            <li>
              <Link to="/login">로그인</Link>
            </li>
              </>
              :
              <>
              <li>
              <a href="" onClick={ e => logout()}>로그아웃</a>
            </li>
            <li>
              <Link to="/board">자유게시판</Link>
            </li>
            <li>
              <Link to="/main/info">마이 페이지</Link>
            </li>
            
              </>
            }
          </ul>  
        </nav>  
      </header>    
    </>
  );
}