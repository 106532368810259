import { useMemo, useRef, useState } from "react"
import axios_api from 'axios';
import axios_back from '../config/AxoisConfig';
import gov from '../store/gov_list'
import '../css/main.css';
import KakaoMap from "./KakaoMap";

export default function Main() {
  const edu_select = useRef();
  const edu_name = useRef();
  const [info , setInfo] = useState({});
  const [edu_list, setEduList] = useState([]);
  const select_gov_list = useMemo(() => {
    return <select ref={edu_select}>
      {
        gov.gov_list.map((gov) => {
          return (
            <option key={gov.CODE} value={gov.CODE}>{gov.EDU_GOV_NAME.substring(0, gov.EDU_GOV_NAME.length - 3)}</option>
          )
        })
      }
    </select>
  }, []);



  const search_edu = () => {
    axios_api.get('https://open.neis.go.kr/hub/acaInsTiInfo', {
      params: {
        KEY: 'e24ed5291b1b411f9957b1c7dc36a56f',
        Type: 'json',
        pIndex: 1,
        pSize: 100,
        ATPT_OFCDC_SC_CODE: edu_select.current.value,
        ACA_NM: edu_name.current.value
      }
    }).then(result => {
      console.log(result);
      const data = result.data.acaInsTiInfo[1].row;
      setEduList(data);
      console.log(data);
      let tag = '<table>';
      tag += '<tr>';
      tag += '<th>종류</th>';
      tag += '<th>학원명</th>';
      tag += '<th>지역</th>';
      tag += '<th>전화번호</th>';
      tag += '<th>주소</th>';
      tag += '</tr>';
      data.map((edu) => {
        tag += `<tr key='${edu.ACA_ASNUM}'>`;
        tag += `<td>${edu.REALM_SC_NM}</td>`;
        tag += `<td>${edu.ACA_NM}</td>`;
        tag += `<td>${edu.ADMST_ZONE_NM}</td>`;
        tag += `<td>${edu.FA_TELNO}</td>`;
        tag += `<td class='address'>${edu.FA_RDNMA} ${edu.FA_RDNDA}</td>`;
        tag += '</tr>';
      })
      tag += '</table>';
      document.querySelector('.result').innerHTML = tag;
      document.querySelectorAll('.address').forEach((element) => {
        element.addEventListener('click', (e) => {
          const info = {name : e.target.parentElement.children[0].textContent,address: e.target.innerHTML};
          setInfo(info);
          console.log(e.target.parentElement.attributes.key.value);
          axios_back.post('/edu/add',{edu_no : e.target.parentElement.attributes.key.value}).then(result => {
            console.log(result);
          });
        });
      });
    }).catch(error => {
      console.log(error);
      //검색 결과 없음
      let tag = '<table>';
      tag += '<tr>';
      tag += '<th>종류</th>';
      tag += '<th>학원명</th>';
      tag += '<th>지역</th>';
      tag += '<th>전화번호</th>';
      tag += '<th>주소</th>';
      tag += '</tr>';
      tag += '<tr>';
      tag += '<td colspan="5">검색 결과가 없습니다.</td>';
      tag += '</tr>';
      tag += '</table>';
      document.querySelector('.result').innerHTML = tag;
    });
  }

  return (
    <>
      <article className="edu_container">
        <h2>학원 정보 검색</h2>
        <div>
          {select_gov_list}
          <input type="text" placeholder="학원명을 입력하세요." ref={edu_name} />
          <button onClick={(e) => search_edu()}>검색</button>
        </div>
        <hr></hr>
        <section>
          <div className="result"></div>
          <KakaoMap name={info.name} address={info.address}/>
        </section>
      </article>
    </>
  )
}