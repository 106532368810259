import axios from 'axios';
import { store } from '../store/store';

// Axios 인스턴스 생성
const instance = axios.create({
  baseURL: 'https://nam3324.synology.me:33333', // 백엔드 서버 주소
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터 설정
instance.interceptors.request.use(
  config => {
    const state = store.getState();
    const token = state.member.value.token;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
