import axios from "../config/AxoisConfig";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import '../css/board_list.css';
import Pagination from 'tui-pagination';
import 'tui-pagination/dist/tui-pagination.css';

export default function BoardMain() {
  let pageNo = useState(1);
  let [contentList, setContentList] = useState([]);
  let [pagging, setPagging] = useState({});
  let pagination = null;
  //엑시오스로 서버에서 데이터를 가져오는 코드
  useEffect(() => {
    axios.get('/board/list?pageNo=1&pageContentEa=20')
      .then(response => {
        console.log(response);
        setContentList(response.data.boardList);
        setPagging(response.data.pagging);
      });
  }, []);

  //toast ui pagination
  useEffect(() => {
    const container = document.getElementById('pagination');
    console.log(pagging);
    pagination = new Pagination(container, {
      totalItems: pagging.totalPage, //전체 페이지 개수
      itemsPerPage: 1, //한번에 보여줄 페이지 개수
      visiblePages: 4, //화면에 보여줄 페이지 개수
      page: pagging.currentPage, //현제 페이지
      centerAlign: true //페이지 중앙 정렬
    });
    console.log(pagination);
    pagination.on('afterMove', (event) => {
        handlePageClick(event.page);
      }
    );
  }, [pagging]);



  const handlePageClick = (pageNo) => {
    axios.get(`/board/list?pageNo=${pageNo}&pageContentEa=20`)
      .then(response => {
        setContentList(response.data.boardList);
        setPagging(response.data.pagging);
      });
  };
  
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
            <th>조회수</th>
            <th>좋아요</th>
            <th>싫어요</th>
          </tr>
        </thead>
        <tbody>
          {contentList.map((content,index) => {
            return (
              <tr key={index}>
                <td>{content.boardNo}</td>
                <td><Link to={"/board/"+content.boardNo}>{content.boardTitle}</Link></td>
                <td>{content.boardMemberNick}</td>
                <td>{content.boardWriteDate}</td>
                <td>{content.boardCount}</td>
                <td>{content.boardLike}</td>
                <td>{content.boardHate}</td>
              </tr>
            );
          },'')}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="7" style={{position : 'relative'}}>
              <div className='board_footer'>

              {
                pagging.priviousPageGroup ? <a onClick={() => {
                  axios.get('/board/list?pageNo=' + (pagging.startPageOfPageGroup - 1) + '&pageContentEa=20')
                  .then(response => {
                    setContentList(response.data.boardList);
                    setPagging(response.data.pagging);
                  });
                }}>◀︎</a> : ''  
              }
              
              {Array.from({ length: pagging.endPageOfPageGroup - pagging.startPageOfPageGroup + 1 }, (_, i) => {
                const pageNo = pagging.startPageOfPageGroup + i;
                return (
                  <a key={pageNo} onClick={() => handlePageClick(pageNo)}>
                    {pageNo}
                  </a>
                );
              })}
        


              {
                pagging.nextPageGroup ? <a onClick={() => {
                  axios.get('/board/list?pageNo=' + (pagging.endPageOfPageGroup + 1) + '&pageContentEa=20')
                  .then(response => {
                    setContentList(response.data.boardList);
                    setPagging(response.data.pagging);
                  });
                }
                
              }>►</a>  : ''} 
              </div>
              <div style={{'position':'absolute','right' :'0px','top' : '0px'}}>
                <Link to="/board/write" style={{padding:'10px', display:'inline-block',textDecoration:'none', width:'100px',backgroundColor:'#00f',color:'white'}}>글쓰기</Link>
              </div>
            </td>

          </tr>
          <tr>
            <td colSpan={7}>
              <div id="pagination" className="tui-pagination"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}