import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "../config/AxoisConfig";
import { useSelector } from 'react-redux';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import '../css/board_write.css';

export default function BoardWrite() {

	const txtTitle = useRef();
	const info = useSelector(state => state.member.value);
	const navigate = useNavigate();
	const content = `# 안녕하세요 토스트 에디터입니다.`;
	let editor = null;
	useEffect(() => {
		editor = new Editor({
			el: document.querySelector('#editor'),
			previewStyle: 'vertical',
			height: '500px',
			initialEditType: 'wysiwyg',
			content : '',
			placeholder : '글을 작성해주세요'
		});
	}, []);


  const writeClick = () => {
		let file = document.querySelectorAll('input[type="file"]');
		let formData = new FormData();
		let params ={
			boardTitle: txtTitle.current.value,
			boardContent: editor.getHTML(),
			token: info.token
		}
		console.log(params);
		console.log(editor.getHTML());
		console.log(editor.getMarkdown());
		formData.append('params', new Blob([JSON.stringify(params)], {type: 'application/json'}));
		for(let i = 0; i < file.length; i++){
			formData.append('file', file[i].files[0]);
		}
		axios.post('/board/insert', formData, {headers: {
			'Content-Type': 'multipart/form-data'
		}}).then(response => {
			console.log(response);
			navigate("/board/"+response.data.bno);
		});
	}
		
	
	return (
		<div>
			<div className="main-container">
			<h2>게시판 글쓰기 페이지</h2>
				<input type='text' className='txt_title' ref={txtTitle} placeholder='제목을 입력하세요'/>
				<div id="editor"></div>
			</div>
			<div className='file_form'>
			파일 1: <input type="file" name="file"/><br/>
			파일 2: <input type="file" name="file"/><br/>
			파일 3: <input type="file" name="file"/><br/>
			</div>
      <hr></hr>
			<div className='board_write_footer'>
      	<button onClick={() => {
					window.history.back();
				}}>뒤로가기</button>
				<button onClick={writeClick}>글쓰기</button>

			</div>
		</div>
	);
}

