import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import Header from './components/Header';
import Register from './components/Register';
import Login from './components/Login';
import Main from './components/Main';
import MyPage from './components/MyPage';
import BoardMain from './components/BoardMain';
import BoardWrite from './components/BoardWrite';
import BoardViewPage from './components/BoardViewPage';

function App() {
  return (
    <Router>
      <Header/>
      <hr/>
      <Routes>
        <Route path="/" element={<Main/>} />        
        <Route path="/register" element={<Register />} />  
        <Route path='/login' element={<Login/>}/>
        <Route path='/main/info' element={<MyPage/>}/>
        <Route path='/board' element={<BoardMain/>}/>
        <Route path='/board/write' element={<BoardWrite/>}/>
        <Route path="/board/:bno" element={<BoardViewPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
