import React, { useEffect } from 'react';

const {kakao} = window;

export default function KakaoMap(props) {
  const {name,address} = props;
  const [map, setMap] = React.useState(null);
  const style = {
    height: '600px'
  }
  console.log(name,address);
  
  useEffect(() => {
    var container = document.getElementById('map');
    var options = {
      center: new kakao.maps.LatLng(33.450701, 126.570667),
      level: 3
    };
    
    var map = new kakao.maps.Map(container, options);
    setMap(map);
  },[]);
  useEffect(() => {
    if(name == undefined || address == undefined) {
      return;
    }
    if(map) {
      var geocoder = new kakao.maps.services.Geocoder();
      geocoder.addressSearch(address, function(result, status) {
        if (status === kakao.maps.services.Status.OK) {
          var coords = new kakao.maps.LatLng(result[0].y, result[0].x);
          map.setCenter(coords);
          var marker = new kakao.maps.Marker({
            position: coords,
          });
          var customOverlay = new kakao.maps.CustomOverlay({
            content: `<div style="text-align:center;padding:6px;background-color:#fff;border:1px solid black;border-radius:5px;margin-bottom:120px">${name}</div>`,
            position: coords
          });
          customOverlay.setMap(map);
          marker.setMap(map);
        }
      });
    }
  }, [address, map]);
  return <div id="map" style={style}></div>;
}