const gov_list = [
  {
    "CODE": "B10",
    "EDU_GOV_NAME": "서울특별시교육청"
  },
  {
    "CODE": "C10",
    "EDU_GOV_NAME": "부산광역시교육청"
  },
  {
    "CODE": "D10",
    "EDU_GOV_NAME": "대구광역시교육청"
  },
  {
    "CODE": "E10",
    "EDU_GOV_NAME": "인천광역시교육청"
  },
  {
    "CODE": "F10",
    "EDU_GOV_NAME": "광주광역시교육청"
  },
  {
    "CODE": "G10",
    "EDU_GOV_NAME": "대전광역시교육청"
  },
  {
    "CODE": "H10",
    "EDU_GOV_NAME": "울산광역시교육청"
  },
  {
    "CODE": "I10",
    "EDU_GOV_NAME": "세종특별자치시교육청"
  },
  {
    "CODE": "J10",
    "EDU_GOV_NAME": "경기도교육청"
  },
  {
    "CODE": "K10",
    "EDU_GOV_NAME": "강원도교육청"
  },
  {
    "CODE": "M10",
    "EDU_GOV_NAME": "충청북도교육청"
  },
  {
    "CODE": "N10",
    "EDU_GOV_NAME": "충청남도교육청"
  },
  {
    "CODE": "P10",
    "EDU_GOV_NAME": "전라북도교육청"
  },
  {
    "CODE": "Q10",
    "EDU_GOV_NAME": "전라남도교육청"
  },
  {
    "CODE": "R10",
    "EDU_GOV_NAME": "경상북도교육청"
  },
  {
    "CODE": "S10",
    "EDU_GOV_NAME": "경상남도교육청"
  },
  {
    "CODE": "T10",
    "EDU_GOV_NAME": "제주특별자치도교육청"
  }
]

export default { gov_list }